<template>
  <v-contextual-menu
    :items="message.isEditable? Object.values(messageOptions) : []"
    @input="onMenuSelect"
  >
    <template #button>
      <div
        class="text-message"
        :class="position"
      >
        <span class="text-message-type">
          {{ fio }}
        </span>
        <p
          :style="messagePaddingStyle"
        >
          {{ message.text }}
        </p>
        <div
          class="info"
        >
          <span
            class="info-time"
            :style="{right:!message.member? '30px': '10px'}"
          >
            {{ createdAtFormatted }}
          </span>
          <b-icon
            v-if="message?.member?.memberType === 2"
            class="info-read"
            :icon="isWasRead ? 'check-all' : 'check'"
          />
        </div>
      </div>
    </template>
  </v-contextual-menu>
</template>

<script>
import { format, parseISO } from '@evd3v/date-fns';
import { VContextualMenu } from '@doctis.front/doctis.designsystem';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import { chatService } from '@/services';
import { showCustomMessage } from '@/helpers/messages';

export default {
  name: 'ChatTextMessage',
  components: { VContextualMenu },
  props: {
    message: {
      type: Object,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    appealId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messageOptions: {
        EDIT: {
          key: 'Edit',
          title: 'Редактировать',
          // action: () => {
          //   console.log('Повторить');
          // },
        },
      },
    };
  },
  computed: {
    options() {
      if (this.message.isEditable) return this.messageOptions;
      return null;
    },
    createdAtFormatted() {
      return format(parseISO(this.message.createdAt), 'HH:mm');
    },
    isOperator() {
      return this.$store.state.Auth.user;
    },
    fio() {
      return !this.message.payload ? this.userFio(this.message) : '';
    },
    isWasRead() {
      return this.message.wasRead;
    },
    messagePaddingStyle() {
      return {
        paddingTop: this.message?.member?.personData ? '' : '10px',
      };
    },
  },
  methods: {
    onMenuSelect() {
      uiService.showModal(MODALS.MESSAGE_EDIT_MODAL, {
        name: 'MessageEditModal',
        props: {
          message: this.message,
          appealId: this.appealId,
        },
        actions: {
          editSendMessage: async (text) => {
            try {
              await chatService.editMessage({ chatAppealId: this.appealId, messageId: this.message.id, text });
            } catch (err) {
              console.error(err);
              showCustomMessage('error', 'Что-то пошло не так', 'Ошибка при редактировании сообщения');
            }
          },
        },
      });
    },
    userFio(message) {
      if (!message?.member?.personData) return '';
      let { lastName, firstName } = message.member.personData;
      lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
      firstName = `${firstName.charAt(0).toUpperCase()}.`;
      return `${lastName} ${firstName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-message {
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  max-width: 100%;
  position: relative;
  padding: 0px 30px 1px 10px;
  background: #066aff18;
  border-radius: 8px;
    &-type{
      font-size: 10px;
    }
  &-text {
    padding-right: 30px;
    margin-bottom: 1px;
    white-space: break-spaces;
  }
  &.left {
    background: #e7e9e9;
  }

  &-message {
    font-size: 16px;
  }
}
.info {
  position: absolute;
  bottom: 0;
  right: 5px;
  display: flex;
  align-items: center;
  font-size: 10px;
    &-time {
      color: #96A9B3;
      padding-right: 5px;
    }
    &-read{
      color: #62bcec;
      font-size: 15px;
    }
}
p {
  line-height: 13px;
  font-size: 0.8rem;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
